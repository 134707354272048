import React, { FunctionComponent } from 'react'
import { Link, PageProps, graphql, navigate } from 'gatsby'
import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react'

import { WpPage } from '../../graphql-types'

import { FlexibleContent } from '../components/flexibleContent/FlexibleContent'
import { Layout } from '../components/layout'
import { SEO } from '../components/seo'

interface IDataProps {
  wpPage: WpPage
}

const PageTemplate: FunctionComponent<PageProps<IDataProps>> = ({ data }) => {
  const {
    wpPage: { flexibleContent, id, title },
  } = data

  return (
    <Layout>
      <SEO title={title ? title : ''} />
      <Container maxW="4xl">
        <Grid gridGap="16">
          <FlexibleContent {...flexibleContent} />
        </Grid>
      </Container>
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query pagePage($id: String) {
    wpPage(id: { eq: $id }) {
      flexibleContent {
        content {
          ... on WpPage_Flexiblecontent_Content_Simple {
            align
            content {
              ... on WpPage_Flexiblecontent_Content_Simple_Content_Paragraph {
                fieldGroupName
                text
              }
              ... on WpPage_Flexiblecontent_Content_Simple_Content_List {
                fieldGroupName
                listItems {
                  fieldGroupName
                  listItem
                }
              }
            }
            fieldGroupName
            heading
            headingStyle
            link
            subheading
            subheadingStyle
            text
          }
          ... on WpPage_Flexiblecontent_Content_Quote {
            fieldGroupName
            quote
            caption
          }
          ... on WpPage_Flexiblecontent_Content_Image {
            fieldGroupName
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Flexiblecontent_Content_Columns {
            fieldGroupName
            template
            columnOne {
              ... on WpPage_Flexiblecontent_Content_Columns_ColumnOne_Simple {
                align
                content {
                  ... on WpPage_Flexiblecontent_Content_Columns_ColumnOne_Simple_Content_Paragraph {
                    fieldGroupName
                    text
                  }
                  ... on WpPage_Flexiblecontent_Content_Columns_ColumnOne_Simple_Content_List {
                    fieldGroupName
                    listItems {
                      fieldGroupName
                      listItem
                    }
                  }
                }
                fieldGroupName
                heading
                headingStyle
                link
                subheading
                subheadingStyle
                text
              }
              ... on WpPage_Flexiblecontent_Content_Columns_ColumnOne_Quote {
                caption
                fieldGroupName
                quote
              }
              ... on WpPage_Flexiblecontent_Content_Columns_ColumnOne_Image {
                fieldGroupName
                image {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            columnTwo {
              ... on WpPage_Flexiblecontent_Content_Columns_ColumnTwo_Simple {
                align
                content {
                  ... on WpPage_Flexiblecontent_Content_Columns_ColumnTwo_Simple_Content_Paragraph {
                    fieldGroupName
                    text
                  }
                  ... on WpPage_Flexiblecontent_Content_Columns_ColumnTwo_Simple_Content_List {
                    fieldGroupName
                    listItems {
                      fieldGroupName
                      listItem
                    }
                  }
                }
                fieldGroupName
                heading
                headingStyle
                link
                subheading
                subheadingStyle
                text
              }
              ... on WpPage_Flexiblecontent_Content_Columns_ColumnTwo_Quote {
                caption
                fieldGroupName
                quote
              }
              ... on WpPage_Flexiblecontent_Content_Columns_ColumnTwo_Image {
                fieldGroupName
                image {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
      id
      slug
      title
    }
  }
`
